import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const RawViewModal=({payload})=> {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return <>
      <Button variant="primary" onClick={handleShow}>
        View json
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Raw json</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <textarea class="form-control" id="exampleFormControlTextarea1" rows="6" disabled="true">{JSON.stringify(payload)}</textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
}

export default RawViewModal;