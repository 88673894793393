import Aistin3DataParser from "./Aistin3DataParser";
import RadarEnvelopeGraphParser from "./RadarEnvelopeGraphParser";
import RawViewModal from "./RawViewModal";
const Aistin3Data = ({payload}) =>{
	return <>
		{payload.aistinuid === "CF"?<RadarEnvelopeGraphParser  payload={payload}/>:<></>}
		{payload.aistinuid !== "CF"?<Aistin3DataParser  payload={payload}/>:<></>}	
		<RawViewModal payload={payload} />
		<hr />
	</>
}

export default Aistin3Data;