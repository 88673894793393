import RawViewModal from "./RawViewModal";

const Aistin5Data = ({payload}) =>{
	let dataArray = [];
	let idCounter = 0;
	if(payload.uidData){
		//Look thru all data entries
		dataArray = Object.keys(payload.uidData).map((key)=>{
			idCounter++
			var nameComponent = key.charAt(0).toUpperCase() + key.slice(1);
			return {data:nameComponent+": "+payload.uidData[key],keyId:"aistin5-"+payload.aistinuid+idCounter};
		})
	}
	return <>
	<h3>Type {payload.aistinuid}</h3>
	<p>
	Address: {payload.address} <br />
	Time: {payload.time} <br />
	Gateway: {payload.gateway} <br />
	Version: {payload.version} <br />
	</p>
	{dataArray.map((field)=>{return <span key={field.keyId}>{field.data} <br /></span>})}
	<RawViewModal payload={payload} />
	<hr />
	</>
}

export default Aistin5Data;

/*  "address": "3e29a53b860e9acf",
  "aistinuid": "D0",
  "uidData": {
    "hwVersion": "BTB.1N.23",
    "productionCode": "EC",
    "nordicApeSw": "0.0500",
    "loraModemSw": "0.0000",
    "nrf9160ApeSw": "9.0909",
    "nrf9160ModemSw": "1.0202"
  },
  "time": "2021-09-16T12:42:54.421+00:00",
  "gateway": "MQTT",
  "version": "aistin-5"*/