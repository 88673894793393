import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';


  ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
  );

const RadarEnvelopeGraphParser = ({payload}) =>{

	if(!payload)return <></>

	var xValues = [];
	var yValues = [];

	if(payload && payload.data){
		for(var i=0;i<payload.data[0].value.length;i+=2){
            xValues.push(payload.data[0].value[i]);
            yValues.push(payload.data[0].value[i+1]);
        }
	}
  
	const options = {
	  scales: {
		  x: {
			display: true,
			title: {
			  display: true,
			  text: 'Distance (mm)'
			}
		  },
		  y: {
			display: true,
			title: {
			  display: true,
			  text: 'Amplitude'
			}
		  }
		}
	};

	var data = {
			labels: xValues,
			datasets: [{
			  fill: false,
			  label: 'Data',
			  lineTension: 0,
			  backgroundColor: "rgba(0,0,255,1.0)",
			  borderColor: "rgba(0,0,255,0.1)",
			  data: yValues,
			  pointRadius: 1,
			}]
	  };
	return <>
	<h3>Type {payload.aistinuid}</h3>
	Address: {payload.address} <br />
	Name: {payload.name} <br />
	Time: {payload.time} <br />
	Gateway: {payload.gateway} <br />
	Version: {payload.version} <br /><br />
	<h5>Distance envelope data</h5>
	{payload && payload.data?<Line options={options} data={data} />:<></>}
	</>
}

export default RadarEnvelopeGraphParser;