import { useEffect, useState } from 'react';
import DataContainer from './DataContainer';
import logo from './img/aistin.png';
import footer_logo from './img/footer_logo.png'



function Datastream() {
	const [fetchInterval,setFetchInterval] = useState(5);
	const [deviceId, setDeviceId] = useState("");
	const [periodicId, setPeriodicId] = useState(undefined);
	const [latestData, setLatestData] = useState("");
	const [updateEnabled,setUpdateEnabled]=useState(true);
	const url = process.env.REACT_APP_BACKEND_PORT?window.location.protocol+"//"+window.location.hostname+`:${process.env.REACT_APP_BACKEND_PORT}`:"";

	const fetchData = async (id)=>{
		const full_url = `${url}/data/${id}`;
		let response = await fetch(full_url)
		.catch((e)=>{
			return undefined;
		});
		if(!response || !response.status || response.status===500){
			console.log(response)
			setLatestData({err:"Network error"});
			return;
		}

		if(response.status===404){
			setLatestData({err:"No data for given id"});
			return;
		}

		response = await response.json().catch((e)=>{
			setLatestData({err:"Internal errorr"})
			return;
		})
		if(response)
			setLatestData(response);
	}

	const startFetch = async(id, int)=>{
		setUpdateEnabled(false);
		if(periodicId){
			clearInterval(periodicId);
		}
		if(id.trim().length>0){
			fetchData(id);
			const periodic = setInterval(()=>fetchData(id),int*1000);
			setPeriodicId(periodic);
		}

		setUpdateEnabled(true);
	}


	useEffect(()=>{
		document.title = "Aistin datastream";
		const queryParams = new URLSearchParams(window.location.search);
		const id = queryParams.get('id');
		if(id){
			setDeviceId(id);
			startFetch(id,fetchInterval);
		}
		return ()=>{
			if(periodicId)
				clearInterval(periodicId);
		}
	},[]);

  return (
	<>
    <main id="mainWrapper">
		<header>
            <img src={logo} alt="iProtoxi" id="logo" />
        </header>

		<section id="content">
            <div id="contentWrapper">
                <article>
                <h1 className="mainHeader">Aistin datastream</h1>
                <p>
                    Aistin datastream is utility to view datastream of a Aistin device routed from the Aistin- cloud service.
                </p>
                </article>
                <div id="selections">            
						<label className="formLabel" htmlFor="deviceId" >DeviceId:</label> 
						<input type="text" id="deviceId" value={deviceId} name="deviceId" onChange={(e)=>setDeviceId(e.target.value)} /> <br />

						<label className="formLabel" htmlFor="interval">Refresh interval (s):</label> 
						<input type="text" id="interval" value={fetchInterval} name="interval" onChange={(e)=>setFetchInterval(e.target.value)}/><br />
                    <input type="button" disabled={!updateEnabled} onClick={()=>{startFetch(deviceId,fetchInterval)}} value="Update" />
                   
                </div>
				<p>
				<DataContainer latestData={latestData} />
				</p>
            </div>
            <p></p>
            <div id="contact"><a href="https://www.iprotoxi.fi/contact/" target="_blank">More info</a></div>
        </section>

		<footer id="copyright">
            <img id="footer-logo" src={footer_logo} alt="iprotoxi" />
            <p>Copyright (C) 2021 iProtoxi Oy</p>
        </footer>
	</main>
	
	</>
  )
}

export default Datastream;
