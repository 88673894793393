import PIRGraph from "./PIRGraph";

const Aistin3DataParser = ({payload}) =>{
	let dataArray = [];
	//var visualizerData = {};
	if(payload.data && payload.data.aistinuid !== "CF"){
		//Look thru all data entries
		for(let i=0;i<payload.data.length;i++){
			//Within a entry
			const fieldCount = payload.data[i].name.length;
			var pir_data;
			if(payload.data[i].dataID === "B49C"){
				pir_data = payload.data[i].value;
				dataArray.push({keyId:"aistin3-"+payload.aistinuid+dataArray.length ,data:<PIRGraph data={pir_data} />});
				continue;
			}

			/*if(payload,data[i].dataID === "B1D8"){
				visualizerData.x = 
			}*/
			for(let j = 0;j<fieldCount;j++){
				var nameComponent = payload.data[i].name[j];
				nameComponent = nameComponent.charAt(0).toUpperCase() + nameComponent.slice(1);
				let fieldResult = nameComponent + ": " + payload.data[i].value[j] + " " +payload.data[i].unit[j];
				dataArray.push({keyId:"aistin3-"+payload.aistinuid+dataArray.length ,data:fieldResult});
			}	
		}
	}
	return <>
	<h3>Type {payload.aistinuid}</h3>
	Address: {payload.address} <br />
	Name: {payload.name} <br />
	Time: {payload.time} <br />
	Gateway: {payload.gateway} <br />
	Version: {payload.version} <br />
	{dataArray.map((field)=>{return <span key={field.keyId}>{field.data} <br /></span>})}
	</>
}

export default Aistin3DataParser;