
import Aistin3Data from './components/Aistin3Data';
import Aistin5Data from './components/Aistin5Data';
const DataContainer = ({latestData})=>{
	let error = undefined;
	if(latestData.err){
		error=latestData.err;
	}
	if(!latestData){
		error="";
	}

	return <>
	<div id="dataContainer">
	{error ? error: Object.keys(latestData).map(key => {
		if(latestData[key].version==="aistin-3") 
			return <Aistin3Data key={latestData[key].version+latestData[key].aistinuid} payload={latestData[key]} />
		if(latestData[key].version==="aistin-5") 
			return <Aistin5Data a key={latestData[key].version+latestData[key].aistinuid} payload={latestData[key]} />
		return <></>;
	})}
  </div>
  
  </>
}
//if(latestData[key].version==="aistin-3"){
export default DataContainer;