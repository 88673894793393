import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	TimeScale
  } from 'chart.js';

  import { Bar } from 'react-chartjs-2';
  import 'chartjs-adapter-luxon';

  ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	TimeScale
  );


const PIRGraph = ({data}) =>{
	var pir_data = data;
	var xValues=[];
	var yValues=[];
	for(var i=0;i<pir_data.length;i++){
		xValues.push(pir_data[i]);
		yValues.push(1);
	}

	const options = {
		scales: {
			x: {
				display: true,
				title: {
					display: true,
					text: 'Time'
				},
			type: 'time',
			time: {
				unit: 'minute'
			} 
			},
			y: {
				display: true,
				title: {
					display: true,
					text: 'Movement detected'
				}
			}
		}
	};

	var graph_data = {
		labels: xValues,
		datasets: [{
			fill: false,
			label: 'Data',
			lineTension: 0,
			backgroundColor: "rgba(0,0,255,1.0)",
			borderColor: "rgba(0,0,255,0.1)",
			data: yValues,
		}]
	};
return <><br />
<h5>Presence</h5>
<Bar options={options} data={graph_data} />
</>

}

export default PIRGraph;