
import Datastream from './Datastream';
//import GraphTool from './GraphTool';
//import Layout from './Layout';
//import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
	
	//const url = process.env.REACT_APP_BACKEND_PORT?window.location.protocol+"//"+window.location.hostname+`:${process.env.REACT_APP_BACKEND_PORT}`:"";

  return (
    <Datastream></Datastream>
  )
}

export default App;
